// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-archive-js": () => import("../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-travel-js": () => import("../src/templates/travel.js" /* webpackChunkName: "component---src-templates-travel-js" */),
  "component---src-templates-guide-js": () => import("../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-opinion-js": () => import("../src/templates/opinion.js" /* webpackChunkName: "component---src-templates-opinion-js" */),
  "component---src-templates-tech-js": () => import("../src/templates/tech.js" /* webpackChunkName: "component---src-templates-tech-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

